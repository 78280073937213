import { gql } from 'react-apollo';

export const PAGE_METADATA_QUERY_FRAGMENT = gql`
  fragment PageMetadataQueryFragment on Query {
    metadataServiceCategories: serviceCategories(
      where: {
        parent: null
        forResource: { equals: true }
        forOrganization: { equals: true }
        forPerson: { equals: true }
      }
      orderBy: { order: asc }
    ) {
      id
      name
      slug
      isFeatured
      description
    }
    metadataStoreFronts: storeFronts(where: { clientId: { equals: $envClientId } }) {
      id
      clientId
      name
      email
      googleTagManagerId
      ahrefVerification
    }
    metadataStatesOrganization: organizations(where: { type: { slug: { equals: "state" } } }, orderBy: { name: asc }) {
      id
      slug
      name
    }
  }
`;

export const GET_METADATA_DETAILS = gql`
  query GetMetadataDetails($envClientId: String) {
    ...PageMetadataQueryFragment
  }
  ${PAGE_METADATA_QUERY_FRAGMENT}
`;
