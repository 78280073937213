import styled from 'styled-components';
import { motion } from 'framer-motion';

export default {
  Container: styled.div`
    position: relative;
    z-index: 0;
  `,
  FormWrapper: styled(motion.div)`
    position: absolute;
    right: -6px;
    bottom: 0;
    width: 385px;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 40px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    button {
      box-sizing: border-box;
      cursor: pointer;
      outline: 0;
      border: none;
      background-color: transparent;
    }

    input {
      box-shadow: none;
      outline: 0;
      border: none;
      background-color: transparent;
      border-radius: 40px;
    }
  `
};
