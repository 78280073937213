import styled, { css } from 'styled-components';
import Animations from '../../styles/Animations';
import ResponsiveMediaQuery from '../../styles/ResponsiveMediaQuery';

export default {
  NavbarContainer: styled.nav`
    position: relative;
    z-index: 10;

    /* !!!!! Do not add padding here !!!!! */
  `,
  DropdownContainer: styled.div`
    position: relative;
    width: 100%;
  `,
  DropdownContent: styled(Animations.FadeDown)`
    z-index: 5;
    position: absolute;
    right: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light};
    animation-duration: 130ms;
    box-shadow: 0 5px 6px -2px rgba(0, 0, 0, 0.1);
  `,
  LinkList: styled.ul<{ border?: boolean; transparentHeader?: boolean }>`
    list-style: none;
    user-select: none;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    ${(props) =>
      props.border &&
      css`
        &:first-child {
          border-left: 1px solid ${!props.transparentHeader ? props.theme.colors.paragraph : props.theme.colors.light};
          padding-left: 12px;
        }
      `}
  `,
  LinkItem: styled.li<{ noPadding?: boolean }>`
    display: block;
    ${(props) =>
      !props.noPadding &&
      css`
        padding: 6px 12px;
      `}

    svg {
      transition: transform 100ms ease-out;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }

      > * {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    &:active {
      svg {
        transform: scale(1);
        transition: transform 20ms ease-in;
      }
    }
  `,
  DropdownLink: styled.a`
    text-decoration: none;
    padding: 6px 12px;

    &:hover {
      > * {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    ${ResponsiveMediaQuery('laptop')} {
      padding: 0;
      text-align: center;
    }
  `,
  DropdownTwoColumns: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    ${ResponsiveMediaQuery('laptop')} {
      margin: 0 auto;
      width: 100%;
      /* Two columns */
      grid-template-columns: repeat(2, 1fr);
      padding: ${(props) => props.theme.spacing.sp24};
    }
  `,
  DropdownFiveColumns: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    ${ResponsiveMediaQuery('laptop')} {
      margin: 0 auto;
      display: grid;
      width: 100%;
      /* Five columns */
      grid-template-columns: repeat(5, 1fr);
      padding: ${(props) => props.theme.spacing.sp24};
    }
  `,
  DropdownThreeColumns: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    ${ResponsiveMediaQuery('laptop')} {
      margin: 0 auto;
      display: grid;
      width: 100%;
      /* Three columns */
      grid-template-columns: repeat(3, 1fr);
      padding: ${(props) => props.theme.spacing.sp24};
    }
  `,
  DropdownFourColumns: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    ${ResponsiveMediaQuery('laptop')} {
      margin: 0 auto;
      display: grid;
      width: 100%;
      /* Three columns */
      grid-template-columns: repeat(4, 1fr);
      padding: ${(props) => props.theme.spacing.sp24};
    }
  `
};
