import { css } from 'styled-components';

export const scrollbarCss = css`
  /* width */
  ::-webkit-scrollbar {
    height: ${(props) => props.theme.spacing.sp4};
    width: ${(props) => props.theme.spacing.sp4};
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.border};
    border-radius: ${(props) => props.theme.spacing.sp4};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.borderDark};
    border-radius: ${(props) => props.theme.spacing.sp4};

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }
`;
