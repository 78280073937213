import React from 'react';
import Head from 'next/head';

type AhrefMetaProps = { ahrefVerification?: string | null };

const AhrefMeta: React.FC<AhrefMetaProps> = ({ ahrefVerification }) => (
  <>
    {ahrefVerification && (
      <Head>
        <meta name="ahrefs-site-verification" content={ahrefVerification} />
      </Head>
    )}
  </>
);

export default AhrefMeta;
