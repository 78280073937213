import styled, { css } from 'styled-components';
import Text from '../../styles/Text';

export default {
  Container: styled.div<{ transparentHeader?: boolean }>`
    position: relative;
    z-index: 10;
    min-height: 32px;

    ${(props) =>
      !props.transparentHeader &&
      css`
        background-color: ${props.theme.colors.light};
      `}
  `,
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
  `,
  Item: styled.li`
    display: block;
    min-width: 220px;

    a {
      padding: ${(props) => props.theme.spacing.sp8};
      display: block;
      box-sizing: border-box;
      text-decoration: none;

      > * {
        color: ${(props) => props.theme.colors.paragraph};
        transition: transform 90ms ease-in-out;
      }
    }

    &:hover {
      a {
        > * {
          color: ${(props) => props.theme.colors.primary};
          transform: translateX(5px);
        }
      }
    }
  `,
  HoverEffect: styled.span`
    &:hover {
      ${Text.SmallParagraph} {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    p {
      line-height: initial;
    }
  `
};
