import styled, { css } from 'styled-components';
import Animations from '../../styles/Animations';
import { scrollbarCss } from '../../styles/Helpers/scrollbar';

export default {
  Container: styled.div<{ disabled?: boolean }>`
    position: relative;
  `,
  ClickContainer: styled.div`
    cursor: pointer;
  `,
  OptionsContainer: styled(Animations.FadeDown)<{
    minWidth?: string;
    maxHeight?: string;
    align?: 'left' | 'right';
    maxWidth?: string;
  }>`
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: initial;
    box-sizing: border-box;
    box-shadow: 5px 20px 50px 0 rgba(0, 0, 0, 0.2);

    background-color: ${(props) => props.theme.colors.light};

    z-index: 30;

    max-height: ${(props) => props.maxHeight || '225px'};
    max-width: ${(props) => props.maxWidth || '250px'};
    overflow-y: auto;
    overflow-x: hidden;

    ${(props) =>
      props.minWidth &&
      css`
        min-width: ${props.minWidth};
      `}

    ${(props) =>
      props.align === 'left' &&
      css`
        left: 0;
      `}

    ${(props) =>
      props.align === 'right' &&
      css`
        right: 0;
      `}

      ${scrollbarCss}
  `
};
