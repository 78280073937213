import { useLazyQuery, useMutation } from 'react-apollo';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { GET_USER_NOTIFICATIONS, SET_NOTIFICATION_AS_READ } from '../../apollo/modules/notifications';
import Badge from '../../components/Badge';
import Dropdown from '../../components/Dropdown';
import NotificationBellIcon from '../../components/Icon/NotificationBellIcon';
import { useUser } from '../../lib/auth/hooks';
import { useLocalizedDayjs } from '../../lib/dayjs';
import Animations from '../../styles/Animations';
import Flex from '../../styles/Flex';
import Text from '../../styles/Text';
import {
  GetUserNotificationsQuery,
  GetUserNotificationsQueryVariables,
  SetNotificationAsReadMutation,
  SetNotificationAsReadMutationVariables
} from '../../typings/generated/backendGraphql';
import NotificationsElementStyles from './styles';

type NotificationsElementProps = { transparentHeader?: boolean };

const NotificationsElement: React.FC<NotificationsElementProps> = ({ transparentHeader }) => {
  const dayjs = useLocalizedDayjs();

  // ***********
  // ** User auth
  // ***********
  const { accessToken, user } = useUser();

  // ***********
  // ** Grapqhl declarations
  // ***********

  // ** Queries

  // Get the notifications(this query will probably get the info from the apollo cache)
  const [getUserNotifications, { data }] = useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
    GET_USER_NOTIFICATIONS,
    {
      // This makes the "loading" state to be updated when we run "refetch"
      // if we don't do this, it'll run in background and state will only be updated if the query finishes
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    }
  );

  // ** Mutations

  const [setNotificationsAsRead] = useMutation<SetNotificationAsReadMutation, SetNotificationAsReadMutationVariables>(
    SET_NOTIFICATION_AS_READ,
    {
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      },
      refetchQueries: ['GetUserNotifications']
    }
  );

  // ***********
  // ** Business logic
  // ***********

  // ** Mappers
  const notifications = useMemo(() => data?.notifications?.filter((x) => x.type === 'info'), [data]);
  const unreadCount = useMemo(() => notifications?.filter((x) => !x.viewedAt).length, [notifications]);

  // ** Execution

  useEffect(() => {
    if (accessToken && user?.sub) {
      getUserNotifications({
        variables: { id: user.sub }
      });
    }
  }, [user, getUserNotifications, accessToken]);

  // ** Handlers

  const onDropdownClose = useCallback(() => {
    const unreadNotifications = notifications?.filter((x) => !x.viewedAt);
    if (accessToken && user?.sub && unreadNotifications && unreadNotifications.length > 0) {
      setNotificationsAsRead({
        variables: {
          notificationIds: unreadNotifications.map((x) => x.id),
          id: user.sub,
          viewedAt: dayjs.utc()
        }
      });
    }
  }, [notifications, setNotificationsAsRead, user, dayjs, accessToken]);

  return (
    <div>
      {notifications && notifications?.length > 0 && (
        <Animations.FadeIn>
          <Dropdown
            align="right"
            minWidth="336px"
            onClose={onDropdownClose}
            content={() => (
              <NotificationsElementStyles.NotificationList>
                {notifications?.map((notification) => (
                  <NotificationsElementStyles.Notification key={notification.id} unread={!notification.viewedAt}>
                    <Flex alignItems="center" justifyContent="space-between" gap="sp8">
                      <Text.Caption color="paragraph">
                        <FormattedMessage
                          id={`notifications.${notification.key}`}
                          values={{
                            ...notification.values,
                            link: (msg: string) => <a href={notification.values?.link}>{msg}</a>
                          }}
                        />
                      </Text.Caption>
                      <Text.Caption color="paragraph">{dayjs(notification.createdAt).fromNow()}</Text.Caption>
                    </Flex>
                  </NotificationsElementStyles.Notification>
                ))}
              </NotificationsElementStyles.NotificationList>
            )}
          >
            <Badge content={unreadCount && unreadCount > 0 ? unreadCount : undefined} variant="mini">
              <Animations.ScaleHover inline style={{ cursor: 'pointer' }}>
                <NotificationBellIcon color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'} />
              </Animations.ScaleHover>
            </Badge>
          </Dropdown>
        </Animations.FadeIn>
      )}
    </div>
  );
};

export default NotificationsElement;
