import { gql } from 'react-apollo';

export const PAGE_NOTIFICATIONS_FRAGMENT = gql`
  fragment PageNotificationsFragment on Query {
    notifications(where: { userId: { equals: $id }, type: { equals: info } }, orderBy: { createdAt: desc }, take: 20) {
      id
      type
      key
      values
      createdAt
      viewedAt
    }
    warningNotification: notifications(
      where: { userId: { equals: $id }, type: { equals: warning }, viewedAt: { equals: null } }
      orderBy: { createdAt: asc }
      take: 1
    ) {
      id
      type
      key
      values
      createdAt
      viewedAt
    }
    errorNotification: notifications(
      where: { userId: { equals: $id }, type: { equals: error }, viewedAt: { equals: null } }
      orderBy: { createdAt: asc }
      take: 1
    ) {
      id
      type
      key
      values
      createdAt
      viewedAt
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications($id: Int!) {
    ...PageNotificationsFragment
  }
  ${PAGE_NOTIFICATIONS_FRAGMENT}
`;

export const SET_NOTIFICATION_AS_READ = gql`
  mutation SetNotificationAsRead($id: Int!, $notificationIds: [Int!], $viewedAt: DateTime!) {
    updateManyNotification(
      where: { userId: { equals: $id }, id: { in: $notificationIds } }
      data: { viewedAt: { set: $viewedAt } }
    ) {
      count
    }
  }
`;
