import styled, { css, keyframes } from 'styled-components';
import helpers, { HelperProps } from '../helpers';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const inputStatus = css`
  &::after {
    position: absolute;

    font: normal normal normal 17px/1 'Font Awesome 5 Free';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;

    box-sizing: border-box;

    margin: auto;
    right: 16px;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    height: 1em;
    width: 1em;
  }
`;

const loadingStatus = css`
  ${inputStatus};
  &::after {
    content: '';
    box-sizing: border-box;
    animation: ${spin} 0.6s linear infinite;
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.colors.paragraph};
    border-top-color: transparent;
    border-right-color: transparent;
    line-height: 1;
  }
`;

const successStatus = css`
  ${inputStatus};
  &::after {
    content: '';
    color: ${(props) => props.theme.colors.success};

    display: block;
    background-image: url('/static/icons/checkGreen.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }
`;

const errorStatus = css`
  ${inputStatus};
  &::after {
    content: '';
    color: ${(props) => props.theme.colors.error};

    display: block;
    background-image: url('/static/icons/error.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }
`;

const checkStyling = css`
  transition: all 250ms ease-in-out 0s;
  /* Ref: https://codepen.io/spacemonkey/pen/vmZROv */
  /* Hide the actual checkbox input */
  position: absolute;
  opacity: 0;

  /* & + * = Label right next to the checkbox */
  & + * {
    /* Makes the label clickable */
    cursor: pointer;

    /* Position the label */
    position: relative;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;

    /* Label(text) style */
    color: ${(props) => props.theme.colors.paragraph};
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.4px;
    user-select: none;

    /* Checkbox background */
    &::before {
      /* Display it */
      content: '';
      margin-right: 11px;
      display: inline-block;
      vertical-align: text-top;
      box-sizing: border-box;

      /* Size it */
      width: 22px;
      height: 22px;
      min-width: 22px;

      /* Colorize it */
      border: 2px solid ${(props) => props.theme.colors.border};
      border-radius: 1px;
      background-color: transparent;
    }

    /* Hovered background */
    &:hover {
      &::before {
        background: ${(props) => props.theme.colors.primary};
      }
    }
  }

  /* If checkbox is Checked */
  &:checked + * {
    /* Background */
    &::before {
      background: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }

    /* Checkmark */
    &::after {
      content: '';
      position: absolute;
    }
  }

  /* If checkbox is disabled */
  &:disabled + * {
    /* Label text */
    color: ${(props) => props.theme.colors.paragraph};
    cursor: default;

    /* Checkbox background */
    &::before {
      box-shadow: none;
      background: transparent;
    }
  }
`;

export type InputTextProps = HelperProps & {
  success?: boolean;
  error?: boolean;
};

export default {
  Group: styled.div<HelperProps & { skipFirstMargin?: boolean }>`
    margin-bottom: ${(props) => props.theme.spacing.sp42};

    /* Every child but not children of child */
    > * {
      ${(props) =>
        props.skipFirstMargin
          ? `
        &:not(:first-child) {
          margin-top: ${props.theme.spacing.sp8};
        }
      `
          : `margin-top: ${props.theme.spacing.sp8};`};
    }

    ${helpers};
  `,
  Text: styled.input<InputTextProps>`
    transition: border-color 130ms ease-in-out 0s;
    /* Sizing and positioning */
    box-sizing: border-box;
    outline: none;
    width: 100%;
    padding: 13px;
    font-size: 17px;
    line-height: 26px;
    border-radius: 4px;

    /* Coloring */
    border: 1px solid ${(props) => props.theme.colors.border};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    &:focus {
      ${(props) => (!props.error && !props.success ? `border-color: ${props.theme.colors.paragraph};` : '')};
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.paragraphLight1};
      font-size: 17px;
      line-height: 26px;
      opacity: 1; /* Firefox */
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.background};
      opacity: 0.5;
    }
    ${(props) => (props.height ? `height: ${props.height};` : '')}
    ${(props) =>
      props.success ? `border-color: ${props.theme.colors.success}; color: ${props.theme.colors.success};` : ''};
    ${(props) => (props.error ? `border-color: ${props.theme.colors.error}; color: ${props.theme.colors.error};` : '')};
    ${helpers};
  `,
  TextMultiLine: styled.textarea<InputTextProps>`
    font-family: Arial;
    transition: border-color 130ms ease-in-out 0s;
    /* Sizing and positioning */
    resize: none;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    padding: 13px;
    font-size: 17px;
    line-height: 26px;
    border-radius: 4px;

    /* Coloring */
    border: 1px solid ${(props) => props.theme.colors.border};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    &:focus {
      ${(props) => (!props.error && !props.success ? `border-color: ${props.theme.colors.paragraph};` : '')};
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.paragraphLight1};
      font-size: 17px;
      line-height: 26px;
      opacity: 1; /* Firefox */
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.background};
      opacity: 0.5;
    }
    ${(props) =>
      props.success ? `border-color: ${props.theme.colors.success}; color: ${props.theme.colors.success};` : ''};
    ${(props) => (props.error ? `border-color: ${props.theme.colors.error}; color: ${props.theme.colors.error};` : '')};
    ${helpers};
  `,
  TextStatus: styled.div<{
    success?: boolean;
    error?: boolean;
    isLoading?: boolean;
  }>`
    position: relative;
    ${(props) => (props.success ? successStatus : '')};
    ${(props) => (props.error ? errorStatus : '')};
    ${(props) => (props.isLoading ? loadingStatus : '')};
  `,
  Checkbox: styled.input.attrs({ type: 'checkbox' })`
    ${checkStyling};

    /* If checkbox is Checked */
    &:checked + * {
      /* Background */
      &::before {
        background: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary};
      }

      /* Checkmark */
      &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url('/static/icons/checkWhite.svg');
        background-repeat: no-repeat;
        background-position: center;
        padding: 3px;
        background-size: 16px 16px;
        height: 16px;
        width: 16px;
      }
    }
  `,
  Radio: styled.input.attrs({ type: 'radio' })`
    ${checkStyling};

    /* & + * = Label right next to the Radio */
    & + * {
      /* Radio background */
      &::before {
        border-radius: 50px;
      }
    }

    /* If Radio is Checked */
    &:checked + * {
      /* Radio */
      &::after {
        display: block;
        background-color: white;
        border-radius: 50px;
        background-position: center;
        height: 8px;
        width: 8px;
        margin-left: 7px;
      }
    }
  `
};
