import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { useDayjsLocale } from '../../components/Providers/localeProvider';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const useLocalizedDayjs = () => {
  const dayjsLocale = useDayjsLocale();
  // I want the instance of dayjs to refresh when the locale changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => dayjs, [dayjsLocale]);
};
