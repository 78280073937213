import styled, { css } from 'styled-components';

export default {
  NotificationList: styled.ul`
    list-style: none;
    user-select: none;
    margin: 0;
    padding: 0;
  `,
  Notification: styled.li<{ unread?: boolean }>`
    display: block;
    user-select: text;
    padding: 12px ${(props) => props.theme.spacing.sp16};

    &:not(:first-child) {
      border-top: 1px solid #dedede;
    }

    ${(props) =>
      props.unread &&
      css`
        background-color: #dedede;
      `}
  `
};
