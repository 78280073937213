import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSortList(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path
          d="M23 17a1 1 0 010 2H1a1 1 0 010-2h22zm0-6a1 1 0 010 2H1a1 1 0 110-2h22zm0-6a1 1 0 010 2H1a1 1 0 110-2h22z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSortList;
