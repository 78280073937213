import { useRouter } from 'next/router';
import qs from 'qs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Search } from '../../components/Icon/16x16';
import { useLocale } from '../../components/Providers/localeProvider';
import Flex from '../../styles/Flex';
import Input from '../../styles/Input';
import NavSearchStyles from './styles';

type NavSearchProps = { transparentHeader?: boolean };

const NavSearch: React.FC<NavSearchProps> = ({ transparentHeader }) => {
  const locale = useLocale();
  const { push } = useRouter();

  const [collapsed, setCollapsed] = useState(true);

  //Search functionality
  const inputRef = useRef<null | HTMLInputElement>(null);
  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (inputRef && inputRef.current) {
        const search = `${qs.stringify(
          { query: inputRef.current.value || undefined },
          {
            addQueryPrefix: true,
            arrayFormat: 'repeat'
          }
        )}`;
        push(`/[locale]/search${search}`, `/${locale}/search${search}`);
      }
    },
    [locale, push]
  );

  // Creates the animation variants
  const variants = useMemo(
    () => ({
      visible: { width: 385, opacity: 1, display: 'block' },
      hidden: { width: 0, opacity: 0, display: 'none' }
    }),
    []
  );

  return (
    <NavSearchStyles.Container>
      {/* The padding and margin adds some space around the button to be clicked more easily on mobile */}
      <Flex padding="sp16" margin="sp-16" onClick={() => setCollapsed(false)} style={{ cursor: 'pointer' }}>
        <Search width="24px" height="24px" color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'} />
      </Flex>
      <NavSearchStyles.FormWrapper
        animate={collapsed ? 'hidden' : 'visible'}
        initial="hidden"
        variants={variants}
        onMouseLeave={() => setCollapsed(true)}
      >
        <form onSubmit={onSearch}>
          <Flex alignItems="center">
            <Input.Text
              ref={inputRef}
              className="text-gray-600"
              color="paragraph"
              placeholder="Search in all our categories"
            />
            <button type="submit" onClick={onSearch} className="p-3">
              <Search width="24px" height="24px" color="primary" />
            </button>
          </Flex>
        </form>
      </NavSearchStyles.FormWrapper>
    </NavSearchStyles.Container>
  );
};

export default NavSearch;
