import React, { useMemo } from 'react';
import BadgeStyles, { BadgeContentProps } from './styles';

type BadgeProps = BadgeContentProps & {
  content?: number;
  maxValue?: number;
};

const Badge: React.FC<BadgeProps> = ({ children, content: propsContent, maxValue, ...props }) => {
  const content = useMemo(() => (propsContent && propsContent > (maxValue || 9) ? maxValue || '9+' : propsContent), [
    propsContent,
    maxValue
  ]);

  return (
    <BadgeStyles.Container>
      {children}
      {content && <BadgeStyles.Content {...props}>{props.variant !== 'dot' && content}</BadgeStyles.Content>}
    </BadgeStyles.Container>
  );
};

export default Badge;
