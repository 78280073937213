import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '@perimetre/ui';
import { SemiArrowDownTiny } from '../../components/Icon/16x16';
import { useUser } from '../../lib/auth/hooks';
import Flex from '../../styles/Flex';
import Text from '../../styles/Text';
import { useProfilePageUrl } from '../../utils/profileUtils';
import NotificationBarElement from '../NotificationBarElement';
import NotificationsElement from '../Notifications';
import UserNavbarStyles from './styles';
import { useLocale } from '../../components/Providers/localeProvider';
import Animations from '../../styles/Animations';
import Container from '../../styles/Container';

type UserNavbarProps = { transparentHeader?: boolean };

const UserNavbar: React.FC<UserNavbarProps> = ({ transparentHeader }) => {
  const { user, isLoading } = useUser();
  const locale = useLocale();
  const [currentUrl, setCurrentUrl] = useState<string | undefined>();

  // We can only get the origin url on client side
  // So we need an useEffect
  useEffect(() => {
    if (!currentUrl && window?.location?.href) {
      setCurrentUrl(window.location.href);
    }
  }, [currentUrl]);

  const profilePageUrl = useProfilePageUrl();
  const profileAccountPageUrl = useProfilePageUrl('/user');

  return (
    <UserNavbarStyles.Container transparentHeader={transparentHeader}>
      <Container large>
        <Flex alignItems="center" justifyContent="flex-end" gap="sp16" paddingVertical="sp8">
          <NotificationsElement transparentHeader={transparentHeader} />
          {!isLoading && (
            <Animations.FadeIn>
              <Dropdown
                buttonProps={{ className: 'pr-0 pt-0' }}
                placement="bottom-end"
                content={() =>
                  !!user ? (
                    <UserNavbarStyles.List>
                      <UserNavbarStyles.Item>
                        <a href={profilePageUrl || '#'}>
                          <Text.SmallParagraph>
                            <FormattedMessage id="navbar.myProfileAction" />
                          </Text.SmallParagraph>
                        </a>
                      </UserNavbarStyles.Item>
                      <UserNavbarStyles.Item>
                        <a href={profileAccountPageUrl || '#'}>
                          <Text.SmallParagraph>
                            <FormattedMessage id="navbar.myProfileAccountAction" />
                          </Text.SmallParagraph>
                        </a>
                      </UserNavbarStyles.Item>
                      <UserNavbarStyles.Item>
                        <Link href={`/logout?redirectTo=${currentUrl}`} as="/logout">
                          <a>
                            <Text.SmallParagraph>
                              <FormattedMessage id="navbar.logoutAction" />
                            </Text.SmallParagraph>
                          </a>
                        </Link>
                      </UserNavbarStyles.Item>
                    </UserNavbarStyles.List>
                  ) : (
                    <UserNavbarStyles.List>
                      <UserNavbarStyles.Item>
                        <a href={`/api/login?redirectTo=${currentUrl}`}>
                          <Text.SmallParagraph>
                            <FormattedMessage id="navbar.loginAction" />
                          </Text.SmallParagraph>
                        </a>
                      </UserNavbarStyles.Item>
                      {/* TEMPORARILY REMOVE THIS ITEM TO AVOID NEW SIGNUPS https://app.clickup.com/t/1t96pp0
                      <UserNavbarStyles.Item>
                        <a href={`/api/login?redirectTo=${currentUrl}`}>
                          <Text.SmallParagraph>
                            <FormattedMessage id="navbar.signUpAction" />
                          </Text.SmallParagraph>
                        </a>
                      </UserNavbarStyles.Item> */}
                      <UserNavbarStyles.Item>
                        <Link href="/[locale]/join" as={`/${locale}/join`}>
                          <a>
                            <Text.SmallParagraph>
                              <FormattedMessage id="navbar.pricing" />
                            </Text.SmallParagraph>
                          </a>
                        </Link>
                      </UserNavbarStyles.Item>
                    </UserNavbarStyles.List>
                  )
                }
              >
                <UserNavbarStyles.HoverEffect>
                  {!!user ? (
                    <Flex alignItems="center" justifyContent="flex-end" paddingVertical="sp4" gap="sp8">
                      <Text.SmallParagraph
                        inline
                        color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'}
                        weight={300}
                      >
                        <FormattedMessage
                          id="navbar.welcomeUserName"
                          values={{
                            name: `${user.firstName} ${user.lastName}`,
                            bold: (msg: string) => <strong>{msg}</strong>
                          }}
                        />
                      </Text.SmallParagraph>
                      <SemiArrowDownTiny color="primary" />
                    </Flex>
                  ) : (
                    <Flex alignItems="center" justifyContent="flex-end" paddingVertical="sp4" gap="sp8">
                      <Text.SmallParagraph
                        inline
                        color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'}
                        weight={300}
                      >
                        <FormattedMessage id="navbar.logoutDropdown" />
                      </Text.SmallParagraph>
                      <SemiArrowDownTiny color="primary" marginTop="sp4" />
                    </Flex>
                  )}
                </UserNavbarStyles.HoverEffect>
              </Dropdown>
            </Animations.FadeIn>
          )}
        </Flex>
      </Container>
      <NotificationBarElement />
    </UserNavbarStyles.Container>
  );
};

export default UserNavbar;
