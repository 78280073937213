import React from 'react';
import Head from 'next/head';

type GoogleTagManagerProps = { googleTagManagerId?: string | null };

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ googleTagManagerId }) => (
  <>
    {googleTagManagerId && (
      <>
        <Head>
          {/* Ref: https://developers.google.com/tag-manager/quickstart */}
          {/* Google Tag Manager */}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${googleTagManagerId}');`
            }}
          />
          {/* End Google Tag Manager */}
        </Head>
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </>
    )}
  </>
);

export default GoogleTagManager;
