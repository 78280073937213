import Link from 'next/link';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import HamburgerMenu from 'src/components/Icon/24x24/SortList';
import NavSearch from '../../elements/NavSearch';
import UserNavbar from '../../elements/UserNavbar';
import Animations from '../../styles/Animations';
import Container from '../../styles/Container';
import Flex from '../../styles/Flex';
import Spacing from '../../styles/Spacing';
import Text from '../../styles/Text';
import { GetMetadataDetailsQuery } from '../../typings/generated/backendGraphql';
import { Drawer } from '@perimetre/ui';
import { SemiArrowDownTiny } from '../Icon/16x16';
import { useLocale } from '../Providers/localeProvider';
import NavbarStyles from './styles';

type NavLink = {
  name: string;
  children?: NavLink[];
  as?: string;
  href?: string;
};

type NavbarProps = { transparentHeader?: boolean; data?: GetMetadataDetailsQuery; loading?: boolean; clientId: string };

const Navbar: React.FC<NavbarProps> = ({ data, transparentHeader, clientId }) => {
  const locale = useLocale();
  const intl = useIntl();
  const namePlaceholder = useMemo(() => intl.formatMessage({ id: 'storefrontPlaceholder.name' }), [intl]);
  const storeFront = useMemo(
    () =>
      data && data.metadataStoreFronts && data.metadataStoreFronts.length > 0 ? data.metadataStoreFronts[0] : undefined,
    [data]
  );

  const [showDrawer, setShowDrawer] = useState(false);
  const onShowDrawer = useCallback(() => setShowDrawer(!showDrawer), [showDrawer]);

  const [dropdownStateMachine, setDropdownStateMachine] = useState<string | undefined>();

  //Logos versions
  const logoLight = `/static/storeFronts/${clientId}/wordfulLogoLight.svg`;
  const logoDark = `/static/storeFronts/${clientId}/wordfulLogoDark.svg`;

  // Create a list of links to be mapped from
  const linkList = useMemo(
    () => ({
      left: [
        {
          name: 'navbar.browseCategoriesButton',
          children:
            data?.metadataServiceCategories &&
            data.metadataServiceCategories.length > 0 &&
            data.metadataServiceCategories.map((serviceCategory) => ({
              name: serviceCategory.name,
              href: '/[locale]/category/[...categoriesSlugs]',
              as: `/${locale}/category/${serviceCategory.slug}`
            }))
        },
        {
          name: 'navbar.statesButton',
          children:
            data?.metadataStatesOrganization &&
            data.metadataStatesOrganization.length > 0 &&
            data.metadataStatesOrganization.map((state) => ({
              name: state.name,
              href: '/[locale]/state/[...stateSlug]',
              as: `/${locale}/state/${state.slug}`
            }))
        },
        {
          name: 'navbar.resourcesButton',
          children: [
            {
              name: intl.formatMessage({ id: 'navbar.countryGuidesButton' }),
              href: '/[locale]/countryguide',
              as: `/${locale}/countryguide`
            },
            {
              name: intl.formatMessage({ id: 'navbar.blogButton' }),
              href: '/[locale]/blog',
              as: `/${locale}/blog`
            },
            {
              name: intl.formatMessage({ id: 'navbar.faqButton' }),
              href: '/[locale]/faq',
              as: `/${locale}/faq`
            },
            {
              name: intl.formatMessage({ id: 'navbar.specialResourceButton' }),
              href: '/[locale]/export-resources',
              as: `/${locale}/export-resources`
            }
          ]
        }
      ] as NavLink[],
      right: [
        {
          name: 'navbar.aboutButton',
          children: [
            {
              name: intl.formatMessage({ id: 'navbar.whoWeAreLink' }),
              href: '/[locale]/about-us',
              as: `/${locale}/about-us`
            },
            {
              name: intl.formatMessage({ id: 'navbar.partnersLink' }),
              href: '/[locale]/partners',
              as: `/${locale}/partners`
            },
            {
              name: intl.formatMessage({ id: 'navbar.contactButton' }),
              href: '/[locale]/contact',
              as: `/${locale}/contact`
            }
          ]
        }
      ] as NavLink[]
    }),
    [locale, data, intl]
  );

  const dropdownContent = useMemo(() => {
    switch (dropdownStateMachine) {
      case 'navbar.browseCategoriesButton': {
        const links = linkList?.left?.find((link) => link.name === dropdownStateMachine)?.children;
        return (
          <NavbarStyles.DropdownTwoColumns>
            {links &&
              links.length > 0 &&
              links.map((link) => (
                <React.Fragment key={link.name}>
                  <Link passHref href={link.href || '#'} as={link.as}>
                    <NavbarStyles.DropdownLink>
                      <Text.Paragraph as="span" color="paragraph">
                        {link.name}
                      </Text.Paragraph>
                    </NavbarStyles.DropdownLink>
                  </Link>
                </React.Fragment>
              ))}
          </NavbarStyles.DropdownTwoColumns>
        );
      }
      case 'navbar.statesButton': {
        const links = linkList?.left?.find((link) => link.name === dropdownStateMachine)?.children;
        return (
          <NavbarStyles.DropdownFiveColumns>
            {links &&
              links.length > 0 &&
              links.map((link) => (
                <React.Fragment key={link.name}>
                  <Link passHref href={link.href || '#'} as={link.as}>
                    <NavbarStyles.DropdownLink>
                      <Text.Paragraph as="span" color="paragraph">
                        {link.name}
                      </Text.Paragraph>
                    </NavbarStyles.DropdownLink>
                  </Link>
                </React.Fragment>
              ))}
          </NavbarStyles.DropdownFiveColumns>
        );
      }
      case 'navbar.resourcesButton': {
        const links = linkList?.left?.find((link) => link.name === dropdownStateMachine)?.children;
        return (
          <NavbarStyles.DropdownFourColumns>
            {links &&
              links.length > 0 &&
              links.map((link) => (
                <React.Fragment key={link.name}>
                  <Link passHref href={link.href || '#'} as={link.as}>
                    <NavbarStyles.DropdownLink>
                      <Text.Paragraph as="span" color="paragraph">
                        {link.name}
                      </Text.Paragraph>
                    </NavbarStyles.DropdownLink>
                  </Link>
                </React.Fragment>
              ))}
          </NavbarStyles.DropdownFourColumns>
        );
      }
      case 'navbar.aboutButton': {
        const links = linkList?.right?.find((link) => link.name === dropdownStateMachine)?.children;
        return (
          <NavbarStyles.DropdownThreeColumns>
            {links &&
              links.length > 0 &&
              links.map((link) => (
                <React.Fragment key={link.name}>
                  <Link passHref href={link.href || '#'} as={link.as}>
                    <NavbarStyles.DropdownLink>
                      <Text.Paragraph as="span" color="paragraph">
                        {link.name}
                      </Text.Paragraph>
                    </NavbarStyles.DropdownLink>
                  </Link>
                </React.Fragment>
              ))}
          </NavbarStyles.DropdownThreeColumns>
        );
      }
      default:
        return null;
    }
  }, [dropdownStateMachine, linkList]);

  // !!!!! Do not add padding here !!!!!
  return (
    <NavbarStyles.DropdownContainer onMouseLeave={() => setDropdownStateMachine(undefined)}>
      <NavbarStyles.NavbarContainer id="nav">
        {/* !!!!! Do not add padding here !!!!! */}
        <UserNavbar transparentHeader={transparentHeader} />
        {/* !!!!! Do not add padding here !!!!! */}
        <Container large>
          {/* A flex that splits the nav in two parts */}
          <Flex alignItems="center" justifyContent="space-between">
            {/* Left part */}
            <Flex alignItems="center" gap="sp24" paddingBottom="sp32">
              <Link href="/[locale]" as={`/${locale}`}>
                <a>
                  <img
                    src={transparentHeader ? logoLight : logoDark}
                    alt={`${storeFront?.name || namePlaceholder} logo`}
                    className="logo-img"
                  />
                </a>
              </Link>

              {/* Desktop */}
              <div className="hidden lg:block">
                <NavbarStyles.LinkList border transparentHeader={transparentHeader}>
                  {linkList?.left &&
                    linkList.left.length > 0 &&
                    linkList.left.map((link) => (
                      <React.Fragment key={link.name}>
                        {link.children ? (
                          <NavbarStyles.LinkItem
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDropdownStateMachine(link.name)}
                          >
                            <Text.Paragraph
                              as="span"
                              color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'}
                              marginRight="sp4"
                            >
                              <FormattedMessage id={link.name} />
                            </Text.Paragraph>
                            <SemiArrowDownTiny color="primary" />
                          </NavbarStyles.LinkItem>
                        ) : (
                          <Link href={link.href || '#'} as={link.as}>
                            <a style={{ textDecoration: 'none' }}>
                              <NavbarStyles.LinkItem style={{ cursor: 'pointer' }}>
                                <Text.Paragraph
                                  as="span"
                                  color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'}
                                  marginRight="sp4"
                                >
                                  <FormattedMessage id={link.name} />
                                </Text.Paragraph>
                              </NavbarStyles.LinkItem>
                            </a>
                          </Link>
                        )}
                      </React.Fragment>
                    ))}
                </NavbarStyles.LinkList>
              </div>
            </Flex>

            {/* Right part */}
            <div>
              {/* Desktop */}
              <div className="hidden lg:block">
                <Flex alignItems="center" gap="sp24" paddingBottom="sp32">
                  <NavbarStyles.LinkList>
                    {linkList?.right &&
                      linkList.right.length > 0 &&
                      linkList.right.map((link) => (
                        <React.Fragment key={link.name}>
                          {link.children && (
                            <NavbarStyles.LinkItem
                              style={{ cursor: 'pointer' }}
                              onClick={() => setDropdownStateMachine(link.name)}
                            >
                              <Text.Paragraph
                                as="span"
                                color={transparentHeader ? 'paragraphOnInverted' : 'paragraph'}
                                marginRight="sp4"
                              >
                                <FormattedMessage id={link.name} />
                              </Text.Paragraph>
                              <SemiArrowDownTiny color="primary" />
                            </NavbarStyles.LinkItem>
                          )}
                        </React.Fragment>
                      ))}
                    <NavbarStyles.LinkItem noPadding>
                      {/* Search Button */}
                      <NavSearch transparentHeader={transparentHeader} />
                    </NavbarStyles.LinkItem>
                  </NavbarStyles.LinkList>
                </Flex>
              </div>
              {/* Mobile */}
              <div className="lg:hidden">
                {/* Mobile button */}
                {/* The icon has some padding and margin to make it more easily clickable
                  but this also pushes it left a little bit(due to the right part of that padding).
                  this marginLeft pushes it back again, making it correctly positioned while also having a great UX */}
                <Spacing marginLeft="sp24" paddingBottom="sp32">
                  <Animations.ScaleHover style={{ cursor: 'pointer' }} onClick={onShowDrawer}>
                    {/* The padding and margin adds some space around the button to be clicked more easily on mobile */}
                    <Spacing padding="sp16" margin="sp-16">
                      <HamburgerMenu color="primary" />
                    </Spacing>
                  </Animations.ScaleHover>
                </Spacing>

                {/* Mobile drawer */}
                <Drawer
                  isOpen={showDrawer}
                  onOpen={(isOpen) => setShowDrawer(isOpen)}
                  onBack={dropdownStateMachine !== undefined ? () => setDropdownStateMachine(undefined) : undefined}
                >
                  {dropdownStateMachine === undefined ? (
                    <ul>
                      {linkList?.left &&
                        linkList.left.length > 0 &&
                        linkList.left.map((link) => (
                          <React.Fragment key={link.name}>
                            {link.children ? (
                              <NavbarStyles.LinkItem
                                style={{ cursor: 'pointer' }}
                                onClick={() => setDropdownStateMachine(link.name)}
                              >
                                <Text.Paragraph as="span" color="paragraph">
                                  <FormattedMessage id={link.name} />
                                </Text.Paragraph>
                              </NavbarStyles.LinkItem>
                            ) : (
                              <Link href={link.href || '#'} as={link.as}>
                                <a style={{ textDecoration: 'none' }}>
                                  <NavbarStyles.LinkItem style={{ cursor: 'pointer' }}>
                                    <Text.Paragraph as="span" color="paragraph">
                                      <FormattedMessage id={link.name} />
                                    </Text.Paragraph>
                                  </NavbarStyles.LinkItem>
                                </a>
                              </Link>
                            )}
                          </React.Fragment>
                        ))}

                      {linkList?.right &&
                        linkList.right.length > 0 &&
                        linkList.right.map((link) => (
                          <React.Fragment key={link.name}>
                            {link.children && (
                              <NavbarStyles.LinkItem
                                style={{ cursor: 'pointer' }}
                                onClick={() => setDropdownStateMachine(link.name)}
                              >
                                <Text.Paragraph as="span" color="paragraph">
                                  <FormattedMessage id={link.name} />
                                </Text.Paragraph>
                              </NavbarStyles.LinkItem>
                            )}
                          </React.Fragment>
                        ))}
                    </ul>
                  ) : (
                    <>{dropdownContent}</>
                  )}
                </Drawer>
              </div>
            </div>
          </Flex>
        </Container>
      </NavbarStyles.NavbarContainer>
      {dropdownStateMachine !== undefined && dropdownContent && (
        <NavbarStyles.DropdownContent
          className="hidden lg:block"
          onMouseLeave={() => setDropdownStateMachine(undefined)}
        >
          <Container>{dropdownContent}</Container>
        </NavbarStyles.DropdownContent>
      )}
    </NavbarStyles.DropdownContainer>
  );
};

export default Navbar;
