import React, { useState } from 'react';
import DropdownStyles from './styles';

type DropdownProps = {
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  align?: 'left' | 'right';
  content?: (isOpen: boolean) => React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

const Dropdown: React.FC<DropdownProps> = ({ children, content, onOpen, onClose, ...styleProps }) => {
  const [isDropdownHidden, setIsDropdownHidden] = useState(true);

  // This is not being wrapped by onCallback because it would be updated on every render anyways
  // Since it reads from the isDropdownHidden variable.
  const onDropdownChange = (isMouseLeave?: boolean) => {
    const isOldHidden = isDropdownHidden;
    const isNewHidden = isMouseLeave ? true : !isDropdownHidden;

    setIsDropdownHidden(isNewHidden);

    // If we have the callback. It was hidden before, but it's not hidden now
    if (onOpen && isOldHidden && !isNewHidden) {
      onOpen();
    }

    // If we have the callback. It was NOT hidden before, but it's hidden now
    if (onClose && !isOldHidden && isNewHidden) {
      onClose();
    }
  };

  return (
    <DropdownStyles.Container onMouseLeave={() => onDropdownChange(true)}>
      <DropdownStyles.ClickContainer onClick={() => onDropdownChange()}>{children}</DropdownStyles.ClickContainer>
      {!isDropdownHidden && (
        <DropdownStyles.OptionsContainer seconds={0.2} {...styleProps}>
          {content ? content(isDropdownHidden) : null}
        </DropdownStyles.OptionsContainer>
      )}
    </DropdownStyles.Container>
  );
};

export default Dropdown;
