import styled, { css } from 'styled-components';
import Animations from '../../styles/Animations';
import { AppTheme } from '../../styles/Theme';

export type BadgeContentProps = {
  variant?: 'default' | 'mini' | 'dot';
  color?: keyof AppTheme['colors'];
  backgroundColor?: keyof AppTheme['colors'];
};

export default {
  Container: styled.div`
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    vertical-align: middle;
  `,
  Content: styled(Animations.FadeIn)<BadgeContentProps>`
    box-sizing: border-box;
    /* Puts it in front of the container */
    position: absolute;
    z-index: 2;

    /* Remove interactions */
    pointer-events: none;
    user-select: none;

    /* Styles it */
    background-color: ${(props) =>
      props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.primary};

    /* Center everything */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Styles the text */
    line-height: 1;
    color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.light)};

    /* Position it */
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;

    /* transform: scale(0.65); */

    ${(props) =>
      (!props.variant || props.variant === 'default') &&
      css`
        /* Styles it */
        height: 20px;
        min-width: 20px;

        padding: 0 7px;
        border-radius: 10px;

        /* Fixes the issue with the current font that is not correctly aligned */
        padding-bottom: 1px;
        /* padding-right: 7px; */

        /* Styles the text */
        font-size: 0.75rem;
      `}

    ${(props) =>
      props.variant === 'mini' &&
      css`
        /* Styles it */
        height: 13px;

        padding: 0 5px;
        border-radius: 10px;

        /* Fixes the issue with the current font that is not correctly aligned */
        padding-bottom: 1px;
        /* padding-right: 4px; */

        /* Styles the text */
        font-size: 0.65rem;
      `}

      ${(props) =>
        props.variant === 'dot' &&
        css`
          height: 8px;
          padding: 0;
          min-width: 8px;
          border-radius: 4px;
        `}
  `
};
