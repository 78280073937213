import Head from 'next/head';
import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { GET_METADATA_DETAILS } from '../../apollo/modules/metadata';
import AhrefMeta from '../../components/AhrefMeta';
import GoogleTagManager from '../../components/GoogleTagManager';
import env from '../../env';
import { GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables } from '../../typings/generated/backendGraphql';

type AppLayoutProps = Record<string, unknown>;

// The app layout is the equivalent of having a "_app" in older versions of next
// And everything that would go to "_app" goes here instead
// But note that it should come after the apollo HOC in the render tree.
// It should usually also be the first thing in a template, before all other layouts
const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { data } = useQuery<GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables>(GET_METADATA_DETAILS);

  const storeFront = useMemo(
    () =>
      data && data.metadataStoreFronts && data.metadataStoreFronts.length > 0 ? data.metadataStoreFronts[0] : undefined,
    [data]
  );

  const clientId = useMemo(() => env().NEXT_PUBLIC_CLIENT_ID || 'no-client', []);

  return (
    <>
      <Head>
        <title>{storeFront?.name || 'Export Connect'}</title>
        <link rel="shortcut icon" href={`/static/storeFronts/${clientId}/favicon/favicon.ico`} />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/static/storeFronts/${clientId}/favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/static/storeFronts/${clientId}/favicon//favicon-16x16.png`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
      </Head>
      <AhrefMeta ahrefVerification={storeFront?.ahrefVerification} />
      <GoogleTagManager googleTagManagerId={storeFront?.googleTagManagerId} />
      <div id="app-layout">{children}</div>
    </>
  );
};

export default AppLayout;
