import styled from 'styled-components';

export default {
  ErrorNotification: styled.div`
    background-color: ${(props) => props.theme.colors.error};
  `,
  WarningNotification: styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
  `
};
