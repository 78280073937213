import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { GET_METADATA_DETAILS } from '../../apollo/modules/metadata';
import Navbar from '../../components/Navbar';
import env from '../../env';
import { GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables } from '../../typings/generated/backendGraphql';

type NavbarElementProps = { transparentHeader?: boolean };

const NavbarElement: React.FC<NavbarElementProps> = ({ transparentHeader }) => {
  const { data, loading } = useQuery<GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables>(GET_METADATA_DETAILS);

  const clientId = useMemo(() => env().NEXT_PUBLIC_CLIENT_ID || 'no-client', []);

  return <Navbar transparentHeader={transparentHeader} data={data} loading={loading} clientId={clientId} />;
};

export default NavbarElement;
