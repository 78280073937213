import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AppTheme } from '../../styles/Theme';

type NotificationErrorIconProps = {
  color?: keyof AppTheme['colors'];
};

const NotificationErrorIcon: React.FC<NotificationErrorIconProps> = ({ color }) => {
  const theme = useTheme();
  const fill = useMemo(() => (color ? theme.colors[color] : '#EBECED'), [color, theme]);
  return (
    <div>
      <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h32v32H0z" />
          <path
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 20a2 2 0 100 4 2 2 0 000-4zm0-12a2 2 0 00-2 2v6a2 2 0 104 0v-6a2 2 0 00-2-2z"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  );
};

export default NotificationErrorIcon;
