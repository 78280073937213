import styled from 'styled-components';
import helpers, { HelperProps } from '../../../styles/helpers';
import { AppTheme } from '../../../styles/Theme';

export type IconProps = HelperProps & {
  color?: keyof AppTheme['colors'];
  width?: string;
  height?: string;
};

export default {
  Container: styled.div<IconProps>`
    ${helpers};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      ${(props) => (props.width ? `width: ${props.width};` : ``)}
      ${(props) => (props.height ? `height: ${props.height};` : ``)}
      .fillSecondary {
        fill: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
      }
      .strokeSecondary {
        stroke: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
      }
      .fillWhite {
        fill: ${(props) => props.theme.colors.light};
      }
    }
  `
};
