import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { GET_USER_NOTIFICATIONS } from '../../apollo/modules/notifications';
import NotificationErrorIcon from '../../components/Icon/NotificationErrorIcon';
import { useUser } from '../../lib/auth/hooks';
import Container from '../../styles/Container';
import Flex from '../../styles/Flex';
import Text from '../../styles/Text';
import { GetUserNotificationsQuery, GetUserNotificationsQueryVariables } from '../../typings/generated/backendGraphql';
import { useProfilePageUrl } from '../../utils/profileUtils';
import NotificationBarElementStyles from './styles';

type NotificationBarElementProps = Record<string, unknown>;

const NotificationBarElement: React.FC<NotificationBarElementProps> = ({}) => {
  // ***********
  // ** User auth
  // ***********
  const { accessToken, user } = useUser();

  // ***********
  // ** Grapqhl declarations
  // ***********

  // ** Queries

  // Get the notifications(this query will probably get the info from the apollo cache)
  const [getUserNotifications, { data }] = useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
    GET_USER_NOTIFICATIONS,
    {
      // This makes the "loading" state to be updated when we run "refetch"
      // if we don't do this, it'll run in background and state will only be updated if the query finishes
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      }
    }
  );

  // ***********
  // ** Business logic
  // ***********

  const profilePageUrl = useProfilePageUrl();

  // ** Mappers
  const errorNotification = useMemo(() => data?.errorNotification[0], [data]);
  const warningNotification = useMemo(() => data?.warningNotification[0], [data]);

  // ** Execution

  useEffect(() => {
    if (user?.sub) {
      getUserNotifications({
        variables: { id: user.sub }
      });
    }
  }, [user, getUserNotifications]);

  return (
    <div className={(errorNotification || warningNotification) && 'mb-8'}>
      {warningNotification && (
        <NotificationBarElementStyles.WarningNotification>
          <Container>
            <Flex alignItems="center" gap="sp16" paddingVertical="sp16">
              <NotificationErrorIcon color="light" />
              <Text.H4 color="light" weight={900}>
                <FormattedMessage
                  id={`notifications.${warningNotification.key}`}
                  values={
                    warningNotification.key === 'profile.notPublishedYet'
                      ? {
                          ...warningNotification.values,
                          profileLink: (msg: string) => (
                            <Text.H4 as="a" href={profilePageUrl} style={{ textDecoration: 'underline' }}>
                              {msg}
                            </Text.H4>
                          )
                        }
                      : warningNotification.values
                  }
                />
              </Text.H4>
            </Flex>
          </Container>
        </NotificationBarElementStyles.WarningNotification>
      )}

      {errorNotification && (
        <NotificationBarElementStyles.ErrorNotification>
          <Container>
            <Flex alignItems="center" gap="sp16" paddingVertical="sp16">
              <NotificationErrorIcon color="light" />
              <Text.H4 color="light" weight={900}>
                <FormattedMessage id={`notifications.${errorNotification.key}`} values={errorNotification.values} />
              </Text.H4>
            </Flex>
          </Container>
        </NotificationBarElementStyles.ErrorNotification>
      )}
    </div>
  );
};

export default NotificationBarElement;
