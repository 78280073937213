import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AppTheme } from '../../styles/Theme';

type NotificationBellIconProps = {
  color?: keyof AppTheme['colors'];
};

const NotificationBellIcon: React.FC<NotificationBellIconProps> = ({ color }) => {
  const theme = useTheme();
  const fill = useMemo(() => (color ? theme.colors[color] : '#2B3449'), [color, theme]);
  return (
    <div>
      <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <path
            d="M12.364 9.995V6.149c0-2.55-1.954-4.616-4.364-4.616S3.636 3.6 3.636 6.15v3.846a3.2 3.2 0 01-.389 1.538h9.506a3.2 3.2 0 01-.39-1.538zm2.909 3.039H.727c-.97 0-.97-1.533 0-1.533.804 0 1.455-.687 1.455-1.534V6.134C2.182 2.746 4.787 0 8 0c3.213 0 5.818 2.746 5.818 6.134v3.833c0 .847.651 1.534 1.455 1.534.97 0 .97 1.533 0 1.533zm-5.386 1.82C9.497 15.564 8.777 16 8 16c-.778 0-1.497-.437-1.887-1.146-.282-.511.068-.854.629-.854h2.516c.56 0 .91.343.63.854z"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  );
};

export default NotificationBellIcon;
