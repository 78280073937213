import { useEffect, useMemo, useState } from 'react';
import { useLocale } from '../components/Providers/localeProvider';
import env from '../env';
import { buildUrlClass } from './urlUtils';

export const useProfilePageUrl = (pathWithNoLocale?: string) => {
  const locale = useLocale();

  const [originUrl, setOriginUrl] = useState<string | undefined>();

  // We can only get the origin url on client side
  // So we need an useEffect
  useEffect(() => {
    // If the originUrl or currentUrl haven't been set
    // And we have a window(at client side)
    if (!originUrl && window?.location?.origin) {
      setOriginUrl(window.location.origin);
    }
  }, [originUrl]);

  return useMemo(() => {
    const { NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN } = env();
    try {
      // Creates the base url for the profile app
      const profileUrl = buildUrlClass(
        `/${locale}${pathWithNoLocale || ''}`,
        NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN,
        true
      );

      // If the origin url has been set
      if (originUrl) {
        // Set it as a search param
        profileUrl.searchParams.append('originUrl', originUrl);
      }

      // Return the built url
      return profileUrl.toString();
    } catch {
      // If something fails with the url builder, fallback to default string concatenation

      // Remove the slash at the end if any
      const profileConnectUrl =
        NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN &&
        NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN[NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN.length - 1] === '/' // If there's a slash at the last position
          ? NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN.substr(0, NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN.length - 1) // Gets a new string without the slash at end
          : NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN;

      return encodeURIComponent(`${profileConnectUrl}/${locale}${pathWithNoLocale || ''}?originUrl=${originUrl}`);
    }
  }, [locale, originUrl, pathWithNoLocale]);
};
